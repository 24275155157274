var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper"},[_c('b-row',{staticClass:"auth-inner"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center auth-bg",attrs:{"lg":"6"}},[_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-center"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex justify-content-center p-xl-3 align-items-center",attrs:{"lg":"6"}},[_c('b-col',{staticClass:"col-8 text-center auth-bg px-xl-4 py-xl-2 rounded",attrs:{"rounded":""}},[_c('b-card-title',{staticClass:"login-text-color py-1 gradient-title"},[_vm._v(" Sign Up ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{staticClass:"text-left label-text ",attrs:{"label":"First Name","label-for":"register-first_name"}},[_c('validation-provider',{attrs:{"name":"first_name","vid":"first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false : null,"placeholder":"Type your first name"},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"text-left label-text",attrs:{"label":"Last Name","label-for":"register-last_name"}},[_c('validation-provider',{attrs:{"name":"last_name","vid":"last_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-last_name","name":"register-last_name","state":errors.length > 0 ? false : null,"placeholder":"Type your last name"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"text-left label-text",attrs:{"label":"Contact No.","label-for":"register-phone"}},[_c('validation-provider',{attrs:{"name":"phone","vid":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-phone","name":"register-phone","state":errors.length > 0 ? false : null,"placeholder":"Type your contact no."},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"text-left label-text",attrs:{"label":"Email Address","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"john@example.com"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"d-flex justify-content-center mt-2"},[_c('b-button',{staticClass:"btn btn-color rounded btn-sign-up w-100 d-block m-auto py-1",attrs:{"type":"submit","disabled":invalid}},[_c('span',[_vm._v("Submit")])])],1),_c('p',{staticClass:"text-center mt-2 text-muted alreday-account"},[_c('span',[_vm._v("Already have an account?")])]),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('span',{staticClass:"sign-up-color sign-in"},[_vm._v("Sign in ")])])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }