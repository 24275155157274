<template>
  <div class="auth-wrapper">
    <b-row class="auth-inner">
      <!-- Left Text-->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center auth-bg"
      >
        <div class="w-100 d-flex align-items-center justify-content-center">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="6"
        class="d-flex justify-content-center p-xl-3 align-items-center"
      >
        <b-col
          rounded
          class="col-8 text-center auth-bg px-xl-4 py-xl-2 rounded"
        >
          <b-card-title class="login-text-color py-1 gradient-title">
            Sign Up
          </b-card-title>
          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-register-form"
              @submit.prevent="register"
            >
              <!-- username -->
              <b-form-group
                label="First Name"
                label-for="register-first_name"
                class="text-left label-text "
              >
                <validation-provider
                  #default="{ errors }"
                  name="first_name"
                  vid="first_name"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="first_name"
                    name="register-username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Type your first name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Last Name"
                label-for="register-last_name"
                class="text-left label-text"
              >
                <validation-provider
                  #default="{ errors }"
                  name="last_name"
                  vid="last_name"
                  rules="required"
                >
                  <b-form-input
                    id="register-last_name"
                    v-model="last_name"
                    name="register-last_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Type your last name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Contact No."
                label-for="register-phone"
                class="text-left label-text"
              >
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  vid="phone"
                  rules="required"
                >
                  <b-form-input
                    id="register-phone"
                    v-model="phone"
                    name="register-phone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Type your contact no."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email Address"
                label-for="register-email"
                class="text-left label-text"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="email"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="d-flex justify-content-center mt-2">
                <b-button
                  type="submit"
                  :disabled="invalid"
                  class="btn btn-color rounded btn-sign-up w-100 d-block m-auto py-1"
                >
                  <span>Submit</span>
                </b-button>
              </b-form-group>
              <p class="text-center mt-2 text-muted alreday-account">
                <span>Already have an account?</span>
              </p>
              <p class="text-center mt-2">
                <b-link :to="{ name: 'auth-login' }">
                  <span class="sign-up-color sign-in">Sign in </span>
                </b-link>
              </p>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { email, required } from '@validations'
import {
BButton,
BCardText,
BCardTitle,
BCol,
BForm,
BFormCheckbox,
BFormGroup,
BFormInput,
BImg,
BInputGroup,
BInputGroupAppend,
BLink,
BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      sideImg: require('@/assets/images/logo/logo-main.png'),
      // validation
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-undef
        this.sideImg = require('@/assets/images/logo/logo-main.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          this.$store.dispatch('auth/register', {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            phone: this.phone,
          }).then(res => {
            if(res.status_code == 200){
              let userData = res.data.user
              userData.ability = [
                  {
                    action: 'manage',
                    subject: 'all',
                  },
                ]
                useJwt.setToken(res.token)
                localStorage.setItem('userData', JSON.stringify(userData))
                this.$ability.update(userData.ability)
                this.$router
                  .replace(getHomeRouteForLoggedInUser(userData.roles[0].name))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Welcome ${
                          userData.name || userData.username
                        }`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: `You have successfully logged in as ${userData.roles[0].name}. Now you can start to explore!`,
                      },
                    })
                  })
                  .catch(error => {
                    console.log(error)
                    // this.$refs.loginForm.setErrors(error.response.data.error)
                  })
            }
            else{
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'XIcon',
                  variant: 'danger',
                  text: `Register Failed!`,
                },
              })
            }
          }).catch(error => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'XIcon',
                  variant: 'danger',
                  text: `Register Failed!`,
                },
              })
          });
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.login-text-color {
  background: -webkit-linear-gradient(#01185e, #264296, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 53px;
}
.sign-up-color {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  color: #7190ef;
}
.btn-color {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
}

.btn-sign-up {
  background: linear-gradient(315deg, #01185E 0%, #264296 44.92%, #7190EF 100%);
  border-radius: 5px;
}
.btn-sign-up span {
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  color: #ffffff;
  padding: 0px 20px;
}
.alreday-account, .sign-in {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #A8ADAF;
}

.sign-in {
  color: #7190EF;
}
</style>
